import { OktaAuth } from '@okta/okta-auth-js';

const redirectUri = window
  ? window.location.protocol +
    '//' +
    window.location.host +
    '/authorization-code/callback'
  : process.env.REACT_APP_OKTA_REDIRECTURI;

export const postLogoutRedirectUri = window
  ? window.location.protocol + '//' + window.location.host + '/logout-callback'
  : 'http://unset/logout-callback';

const config = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
  redirectUri,
  postLogoutRedirectUri,
  responseType: 'code',
  pkce: true,
  // scopes: ['groups'],

  tokenManager: {
    storage: 'sessionStorage',
  },
};

const authClient = new OktaAuth(config);

async function authInit() {
  // Use commented out code below if need to handle app start authentication tasks

  // authClient.authStateManager.subscribe(function (authState) {
  //   // Logic based on authState is done here.
  //   if (!authState.isAuthenticated) {
  //     // unathenticated
  //     return;
  //   }

  //     authenticated
  // });

  // check if are we just loggin in via redirect.
  if (authClient.token.isLoginRedirect()) {
    const { tokens } = await authClient.token.parseFromUrl(); // remember to "await" this async call

    authClient.tokenManager.setTokens(tokens);
  }

  // normal app startup
  authClient.start(); // will update auth state and call event listeners
}

authInit();

export default authClient;
